.h1-6 {
  color: var(--header-color, var(--default-header-color));
  font-family: var(--font-header);
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  line-height: 1.25;
  margin: 0 0 1rem 0;
}

.h1 {
  font-size: var(--h1-size, var(--default-h1-size));
}

.h2 {
  font-size: var(--h2-size, var(--default-h2-size));
}

.h3 {
  font-size: var(--h3-size, var(--default-h3-size));
}

.h4-5 {
  font-size: var(--h4-5-size, var(--default-h4-5-size));
}

.h4 {
  font-size: var(--h4-size, var(--default-h4-size));
}

.h5 {
  font-size: var(--h5-size, var(--default-h5-size));
}

.h6 {
  font-size: var(--h6-size, var(--default-h6-size));
}

.lite {
  font-family: var(--font-primary);
  font-weight: 400;
  font-style: normal;
}

.noitalic {
  font-style: normal;
}

.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

.highlight {
  color: var(--primary);
}

.tight {
  margin-bottom: 0.4rem;
}

.upper {
  text-transform: uppercase;
}

.block {
  display: block;
  width: 100%;
}

.nomargin {
  margin: 0;
  line-height: 1;
}

.h1-6 i {
  font-weight: 400;
}

.has-subtitle {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.h1-6 .subtitle {
  font-family: var(--font-primary);
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
}
