
.btn-sm {
  @apply px-2 py-1 text-sm;
}

.btn-md {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-4 py-3 text-lg;
}

.btn-icon {
  @apply inline-flex items-center gap-x-1.5;
}

.btn-text, button.btn-text, a.btn-text {
  @apply font-medium outline-none focus:outline-none border-0 focus:border-0;
}

.btn-outline, button.btn-outline, a.btn-outline {
  @apply rounded-full font-medium text-white outline-none focus:outline-none border-2 focus:border-2 border-white hover:border-primary hover:text-primary;
}

.btn-success, button.btn-success, a.btn-success {
  @apply rounded-full bg-green-600 font-medium text-white hover:bg-green-500 outline-none focus:outline-none border-0 focus:border-0;
}

.btn-danger, button.btn-danger, a.button-danger {
  @apply rounded-full bg-red-600 font-medium text-white hover:bg-red-500 outline-none focus:outline-none border-0 focus:border-0;
}

.btn-text-danger, button.btn-text-danger, a.button-text-danger {
  @apply rounded-full bg-none font-medium text-red-600 hover:text-red-500 outline-none focus:outline-none border-0 focus:border-0;
}
