.link {
  color: var(--link-color);
  font-size: var(--btn-font-size);
}

.link-header {
  font-family: var(--font-header);
  font-weight: 300;
}

.link-bold {
  font-weight: 400;
}

.link-block {
  display: flex;
  width: 100%;
}

.btn {
  color: var(--link-color);
}

.btn:hover {
  text-decoration: none;
}

.btn-primary {
  border: 3px solid var(--link-color);
  border-radius: 55px;
  display: inline-block;
  font-size: var(--btn-font-size);
  font-weight: bold;
  letter-spacing: 0;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: var(--fast-transition);
  white-space: pre;
}

.btn-primary:hover {
  border-color: var(--primary);
  color: var(--primary);
}

.btn-active:hover {
  border-color: #fff;
  color: var(--primary);
}

.btn-dark {
  border: 1px solid var(--bg-color);
  background: var(--bg-color);
  color: var(--bg-color);
  display: inline-block;
  font-size: var(--btn-font-size);
  font-weight: bold;
  letter-spacing: 0;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: var(--fast-transition);
}

.btn-dark:hover {
  background: #111;
}

.btn-light {
  border: 1px solid var(--text-color);
  background: var(--text-color);
  color: var(--text-color);
  display: inline-block;
  font-size: var(--btn-font-size);
  font-weight: bold;
  letter-spacing: 0;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: var(--fast-transition);
}

.btn-light:hover {
  background: #eee;
}

.btn-highlight-text {
  color: var(--highlight-text);
}

.btn-highlight-text:hover {
  color: var(--highlight-text);
}

.btn-underline {
  text-decoration: underline;
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-underline:hover {
  text-decoration: underline;
}

.btn-wrapper {
  display: flex;
}

.btn-wrapper-center {
  justify-content: center;
  width: 100%;
}

.btn-active {
  background: var(--text-color);
  color: var(--bg-color);
  border-color: var(--text-color);
  transition: var(--fast-transition);
}

.btn-highlight {
  color: var(--text-color);
  border-color: var(--text-color);
  transition: var(--fast-transition);
}

.btn-highlight:hover {
  background: transparent;
  border-color: var(--text-color);
  color: var(--text-color);
}

.btn-large {
  --btn-font-size: 1.4rem;
  letter-spacing: 2px;
}

.btn-small {
  --btn-font-size: 0.85rem;
  letter-spacing: 0;
}

.btn-spacing {
  padding: 0 1rem;
}

.btn-primary-desktop {
  color: var(--primary);
  text-decoration: underline;
  white-space: pre;
}

.btn-primary-desktop:hover {
  color: var(--primary);
}

.btn-primary-color {
  background-color: var(--primary);
  border: none;
  color: #000;
}

.btn-primary-color:hover {
  background-color: var(--primary);
  border: none;
  color: #000;
}

@media (min-width: 768px) and (min-height: 425px) {
  .btn-primary-desktop {
    color: #fff;
    background: var(--primary);
    border: 1px solid var(--primary);
    display: inline-block;
    font-size: var(--btn-font-size);
    font-weight: bold;
    letter-spacing: 0;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: var(--fast-transition);
  }

  .btn-primary-desktop:hover {
    text-decoration: none;
    color: #fff;
  }
}

@media (min-width: 1200px) {
  .btn-primary {
    padding: 0.25rem 2rem;
  }
}

@media (min-width: 1600px) {
  .btn-primary {
    padding: 0.25rem 2rem;
  }
}
